import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer, PageHeading } from "../components/shared-styles"
import colors from "../utils/colors"

const StyledForm = styled.form`
  width: 100%;

  .hidden {
    display: none;
  }
`

const StyledFormField = styled.div`
  width: 100%;
  margin-bottom: 1em;

  label,
  input,
  textarea {
    display: block;
    width: 100%;
  }

  textarea,
  input {
    border: 1px solid ${colors.blue};
    border-radius: 4px;
    padding: 0.5em;
  }

  label {
    font-weight: 600;
  }
`

const StyledSubmit = styled.button`
  background-color: transparent;
  border-radius: 4px;
  border: solid 1px ${colors.blue};
  color: ${colors.blue};
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    background-color: ${colors.blue};
    color: white;
  }
`

const Contact = () => {
  return (
    <Layout>
      <SEO title={`Contact`} />
      <ContentContainer>
        <PageHeading>Say Hello!</PageHeading>
        <StyledForm
          name="Contact Form"
          method="POST"
          data-netlify="true"
          netlify-honeypot="ignore-human"
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="Contact Form" />
          <p className="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="ignore-human" />
            </label>
          </p>
          <StyledFormField>
            <label>Your Name:</label>
            <input type="text" name="name" required />
          </StyledFormField>
          <StyledFormField>
            <label>Your Email:</label>
            <input type="email" name="email" required />
          </StyledFormField>
          <StyledFormField>
            <label>Message:</label>
            <textarea name="message" required />
          </StyledFormField>
          <StyledSubmit type="submit">Send</StyledSubmit>
        </StyledForm>
      </ContentContainer>
    </Layout>
  )
}

export default Contact
